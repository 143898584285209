<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="client-users-topform"
      :managementType="managementType"
      :status="calculatedStatus"
      pageType="client_user"
      :name="userInfo.name"
      :errors="userErrors"
      :submissionStates="submissionStates"
      parentPage="/client/users"
      :tabs="computedTabs"
      :validation="computedValidation"
      :loadingComplete="loadingComplete"
      @clearErrors="clearErrors"
      @asset_reservation_created="form.avatar = $event"
      @asset_reservation_deleted="form.avatar = null"
      @remove_project_asset="form.avatar_remove = $event"
      @submit="submit"
      @clear_existing_data="clearData"
    >
    </Topform>
    <!--end::TOPFORM-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { minLength, maxLength, required, email } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";

//Vuex Imports
import {
  CREATE_CLIENT_USER,
  UPDATE_CLIENT_USER,
  CLEAR_USER_ERRORS,
  POPULATE_CLIENT_USER_INFO,
  CLEAR_USER_INFO
} from "@/core/services/store/core/user.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageClientUser.js";

export default {
  mixins: [validationMixin],

  name: "ManageClientUser",

  data() {
    return {
      pageLoader: new PageLoader(1),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      error: {
        active: false,
        message: ""
      },
      form: {
        suspended: 0,
        name: "",
        display_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        system_country_code: null,
        system_language_code: null,
        system_timezone_code: null,
        system_client_role_id: 1,
        avatar: null,
        avatar_remove: null
      }
    }
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255)
      },
      display_name: {
        required,
        maxLength: maxLength(255),
        isValid: (value, vm) => {
          let spaceLessValue = value.replace(/\s/g, '').toLowerCase();
          let spaceLessName = vm.name.replace(/\s/g, '').toLowerCase();
          let check1 = !spaceLessValue.includes(spaceLessName)
          let check2 = !spaceLessName.includes(spaceLessValue)
          return check1 && check2;
        }
      },
      email: {
        required,
        maxLength: maxLength(255),
        email
      },
      password: {
        minLength: minLength(8),
        maxLength: maxLength(24)
      },
      password_confirmation: {
        minLength: minLength(8),
        maxLength: maxLength(24)
      },
      suspended: {
        required
      },
      system_country_code: {
        required
      },
      system_language_code: {
        required
      },
      system_timezone_code: {
        required
      },
      system_client_role_id: {
        required
      }
    }
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      {
        title: this.clientInfo.name,
        route: "/client/projects"
      },
      { title: `${this.$t("NAVIGATION.BREADCRUMB.ADD_USER")}` }
    ]);

    if (this.managementType == "update") {
      this.fetchExistingUser();
    } else {
      this.form.system_country_code = this.clientInfo.system_country_code;
      this.form.system_language_code = this.clientInfo.system_language_code;
      this.form.system_timezone_code = this.clientInfo.system_timezone_code;
      this.pageLoader.setCompleteComponent();
    }
  },

  methods: {
    fetchExistingUser: function() {
      let payload = {
        clientId: this.clientInfo.id,
        userId: this.$route.params.id
      };

      this.$store
        .dispatch(POPULATE_CLIENT_USER_INFO, payload)
        .then(() => {
          for (const [key, value] of Object.entries(this.userInfo)) {
            if (key in this.form) {
              this.form[key] = value;
            }
          }
          if (this.userInfo.avatar != null) {
            this.initialAvatar = this.userInfo.project_asset;
          }
          this.form.name = this.userInfo.calculatedName;
          this.form.email = this.userInfo.calculatedEmail;
          this.pageLoader.setCompleteComponent();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the admin roles list, please check back later or contact the helpdesk";
        });
    },
    submit: function() {
      this.clearErrors();

      if (this.managementType == "update") {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    submitUpdate: function() {
      this.submissionStates.submitting = true;

      let payload = {
        userId: this.$route.params.id,
        clientId: this.clientInfo.id,
        data: this.form
      };

      this.$store
        .dispatch(UPDATE_CLIENT_USER, payload)
        .then(() => {
          this.$v.form.$reset();
          this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.EDIT_CLIENT_USER.MESSAGE")}`, `${this.$t("TOASTS.EDIT_CLIENT_USER.TITLE")}`, 'success');
          this.$router.push({
            name: "client_users"
          });
        })
        .catch(() => {})
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitPost: function() {
      this.submissionStates.submitting = true;

      let payload = {
        clientId: this.clientInfo.id,
        data: this.form
      };

      this.$store
        .dispatch(CREATE_CLIENT_USER, payload)
        .then(() => {
          this.$v.form.$reset();
          this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.ADD_CLIENT_USER.MESSAGE")}`, `${this.$t("TOASTS.ADD_CLIENT_USER.TITLE")}`, 'success');
          this.$router.push({
            name: "client_users"
          });
        })
        .catch(() => {})
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_USER_ERRORS);
    },
    clearData: function() {
      this.$store.dispatch(CLEAR_USER_INFO)
    },
    removeAvatar: function(value) {
      this.form.avatar_remove = value;
      this.form.avatar = null;
      this.initialAvatar = null;
    }
  },

  computed: {
    ...mapGetters([
      "clientInfo",
      "userInfo",
      "userErrors",
      "currentUser",
      "systemClientRoles",
      "systemCountries",
      "systemTimezones",
      "systemLanguages"
    ]),
    managementType: function() {
      if (this.$route.params.id != undefined) {
        return "update";
      } else {
        return "post"
      }
    },
    calculatedStatus: function() {
      if (this.managementType == "update" && this.loadingComplete == true) {
        if (this.userInfo.suspended) {
          return "blocked";
        } else if (this.userInfo.setup_token != null) {
          return "invited";
        } else {
          return "subscribed";
        }
      } else {
        return null;
      }
    },
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    gallery: function() {
      let gallery = [];

      if (this.managementType == "update") {
        if (this.userInfo.avatar != null) {
          if (this.userInfo.project_asset.id != this.form.avatar_remove) {
            gallery.push(this.userInfo.project_asset);
          }
        }
      }
      
      return gallery;
    },
    assetReservationData: function() {
      let data = {
        scope: 'user',
        data: {
          directory: 'avatars',
          projectId: null
        }
      }

      if (this.managementType == "post") {
        data.data.new = 1;
      } else {
        data.data.userId = this.$route.params.id
      }

      return data;
    },
    isCurrentUser: function() {
      if (this.managementType == "post") {
        return false;
      } else {
        if (this.currentUser.id == this.$route.params.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    computedValidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    }
  }
}
</script>
