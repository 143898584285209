class ManageClientUser {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
          {
            id: 1,
            sections: [
              { 
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericCheckbox",
                        properties: {
                          class: "col-xl-12 px-5 mb-0",
                          id: 'input-suspended',
                          label: `${component.$t("FORMS.INPUTS.BLOCK")}`,
                          model: component.$v.form.suspended,
                          submissionStates: component.submissionStates
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_INFORMATION")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-name',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.NAME")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.NAME")}`,
                          model: component.$v.form.name,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.NAME")}`
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-display_name',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.DISPLAY_NAME")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.DISPLAY_NAME")}`,
                          model: component.$v.form.display_name,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.DISPLAY_NAME")}`
                        }
                      },
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        show: component.managementType == "post",
                        properties: {
                          class: "col-xl-12 px-5",
                          id: 'input-email',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.EMAIL")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.EMAIL")}`,
                          model: component.$v.form.email,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.EMAIL")}`
                        }
                      }
                    ]
                  },
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_ACCESS_LEVEL")}`,
                show: !component.isCurrentUser,
                rows: [
                  {
                    show: component.currentUser.system_admin_role_id == 1,
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-client-role',
                          label: `${component.$t("FORMS.INPUTS.CLIENT_ROLE")}`,
                          model: component.$v.form.system_client_role_id,
                          options: component.systemClientRoles,
                          optionLabel: "name",
                          value: "id",
                          errorMessage: `${component.$t("FORMS.ERROR.ADMIN_ROLE")}`
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_PASSWORD")}`,
                show: component.isCurrentUser,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-password',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.PASSWORD")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.PASSWORD")}`,
                          model: component.$v.form.password,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.PASSWORD")}`,
                          type: "password"
                        }
                      },
                      {
                        name: "InputGeneric",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-password-confirm',
                          alignLabel: "left",
                          showInline: false,
                          label: `${component.$t("FORMS.INPUTS.PASSWORD")}`,
                          placeholder: `${component.$t("FORMS.INPUTS.PASSWORD_CONFIRM")}`,
                          model: component.$v.form.password_confirmation,
                          submissionStates: component.submissionStates,
                          error: `${component.$t("FORMS.ERROR.PASSWORD")}`,
                          type: "password"
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_LOCALE")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-country-code',
                          label: `${component.$t("FORMS.INPUTS.COUNTRY")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.COUNTRY")}`,
                          model: component.$v.form.system_country_code,
                          options: component.systemCountries,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.COUNTRY")}`,
                        }
                      },
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-language-code',
                          label: `${component.$t("FORMS.INPUTS.LANGUAGE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.LANGUAGE")}`,
                          model: component.$v.form.system_language_code,
                          options: component.systemLanguages,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.LANGUAGE")}`,
                        }
                      }
                    ]
                  },
                  {
                    components: [
                      {
                        name: "InputGenericDropdown",
                        properties: {
                          class: "col-xl-6 px-5",
                          id: 'input-timezone-code',
                          label: `${component.$t("FORMS.INPUTS.TIMEZONE")}`,
                          placeholder: `${component.$t("FORMS.PLACEHOLDER.TIMEZONE")}`,
                          model: component.$v.form.system_timezone_code,
                          options: component.systemTimezones,
                          optionLabel: "name",
                          value: "code",
                          errorMessage: `${component.$t("FORMS.ERROR.TIMEZONE")}`,
                        }
                      }
                    ]
                  }
                ]
              },
              {
                title: `${component.$t("FORMS.SECTION_HEADERS.USER_AVATAR")}`,
                rows: [
                  {
                    components: [
                      {
                        name: "IntegratedDropzone",
                        on: {
                          asset_reservation_created: (value) => { component.$refs["client-users-topform"].$emit('asset_reservation_created', value) },
                          asset_reservation_deleted: (value) => { component.$refs["client-users-topform"].$emit('asset_reservation_deleted', value) },
                          remove_project_asset: (value) => { component.$refs["client-users-topform"].$emit('remove_project_asset', value) },
                        },
                        properties: {
                          class: "col-xl-9",
                          assetReservation: component.assetReservationData,
                          idx: 1,
                          projectAssets: component.gallery,
                          wholeWidth: true,
                          removable: true
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
    }
  
  }
  
export default ManageClientUser;